import { HentAntallUlesteResponse, HentKunAntallUlesteHendelserResponse } from '../types/entities/hendelsesmeny.v2entities';
import { PersonvelgerElement } from '../types/entities/representasjon.v1entities';
import { RepresentasjonsforholdType } from '../types/entities/representasjon.v1enums';

import { PersonvelgerDTO } from '@helsenorge/core-cms/types/entities/cms.v1entities';

export const getRepresentasjonsType = (representasjonsforholdType: RepresentasjonsforholdType, resources: PersonvelgerDTO): string => {
  switch (representasjonsforholdType) {
    case RepresentasjonsforholdType.innloggetBruker:
      return resources.headerMenuProfileRepresentasjonerDegSelv;

    case RepresentasjonsforholdType.foreldreansvar:
      return resources.headerMenuProfileRepresentasjonerDuHarForeldreansvar;

    case RepresentasjonsforholdType.fullmakt:
      return resources.headerMenuProfileRepresentasjonerDuHarFullmakt;

    default:
      return '';
  }
};

export const getAntallUleste = (
  antallUleste: HentAntallUlesteResponse | null,
  kunAntallUleste: HentKunAntallUlesteHendelserResponse | null,
  user: PersonvelgerElement,
  currentUser?: PersonvelgerElement
): number => {
  if (user.profilGuid === currentUser?.profilGuid) {
    return kunAntallUleste?.antallUlesteHendelser ?? 0;
  }

  const antallUlesteForUser = antallUleste?.antallUlesteRepresentertGuids?.find(antallUleste => antallUleste.profilId === user?.profilGuid);

  return antallUlesteForUser?.antall ?? 0;
};

export const upperCaseFirstLetter = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};
