/// <reference types="@helsenorge/framework-utils/types/hn"/>

import React, { useContext, useEffect, useState } from 'react';

import { AccessTokenExpirationOperationResponse, SessionTimeoutAction } from '../../types/entities';

import Modal from '@helsenorge/designsystem-react/components/Modal';

import { trackError } from '@helsenorge/framework-utils/adobe-analytics';
import { hasLoggedInCookie } from '@helsenorge/framework-utils/cookie';
import { isAuthorized } from '@helsenorge/framework-utils/hn-authorize';
import { HelsenorgeHttpError, getTjenesterUrl } from '@helsenorge/framework-utils/hn-proxy-service';
import { get, put } from '@helsenorge/framework-utils/hn-tjeneste-service';
import { setAllowNavigation } from '@helsenorge/framework-utils/hn-user';
import { HeaderFooterEvents } from '@helsenorge/framework-utils/web-component/constants';
import { SubscribeContext } from '@helsenorge/framework-utils/web-component/context';
import { HNeventSoonAutoSignOut } from '@helsenorge/framework-utils/web-component/events';

import { getSessionConfig, getCountdownTime, stopAllTimers, TimerKeys, setTimer } from './session-utils';

interface WarningLightboxProps {
  resources?: {
    lightboxInactivityTitle?: string;
    lightboxInactivityDescription?: string;
    lightboxInactivityTimer?: string;
    lightboxInactivityDoYouWantToStay?: string;
    lightboxInactivityButtonYes?: string;
    lightboxInactivityButtonSignOut?: string;
    inactivitySignedOutTitle?: string;
    inactivitySignedOutButtonGoToRoot?: string;
    inactivitySignedOutButtonSignInAgain?: string;
  };
  onShowSignOutBox?: (data: ShowSignOutBoxData) => SessionTimeoutAction;
}
const AccessTokenExpiration = '/api/v1/AccessTokenExpiration';

export interface ShowSignOutBoxData {
  tokenCountdown: number;
  tokenWarnUserWhenRemaining: number;
  tokenLifetime: number;
}

const WarningLightbox: React.FC<WarningLightboxProps> = ({ resources }) => {
  const subscribe = useContext(SubscribeContext);
  const config = getSessionConfig();
  const { minutes, seconds } = getCountdownTime(config.countdown);
  const [extend, setExtend] = useState(false);
  const [state, setState] = useState({
    automaticSignOutWarning: false,
    minutes: minutes,
    seconds: seconds,
  });

  const replacePlaceholder = (placeholder: string, text: string, value: string): string => {
    if (!text) {
      return '';
    }

    const expression = new RegExp(placeholder, 'g');
    return text.replace(expression, value as string);
  };

  const getExpirationDateFromResponse = (response: AccessTokenExpirationOperationResponse): Date => {
    const timeString = response.Expire as string;
    let expirationMs: number = Date.parse(timeString);
    if (!timeString.endsWith('Z')) {
      expirationMs = Date.parse(timeString + 'Z');
    }
    const expiration: Date = new Date(expirationMs);
    return expiration;
  };

  const triggerAutomaticSignOut = (): void => {
    stopAllTimers();
    setAllowNavigation(true);
    trackError('level4', 'Inactivity');
    window.location.replace(`${getTjenesterUrl()}/Auth/AutoSignOut`);
  };

  const handleError = (error: unknown): void => {
    if (error instanceof HelsenorgeHttpError) {
      triggerAutomaticSignOut();
    }
  };
  const setTimeLeft = (expirationTime: number): void => {
    setState({
      automaticSignOutWarning: true,
      minutes: getCountdownTime(expirationTime - Date.now()).minutes,
      seconds: getCountdownTime(expirationTime - Date.now()).seconds,
    });
  };

  const handleExpiration = (response?: AccessTokenExpirationOperationResponse): void => {
    const expiration: Date | undefined = response && getExpirationDateFromResponse(response);
    const expirationTime = expiration ? expiration.getTime() : config.tokenLifetime + Date.now();
    const warningTime = expirationTime - config.warnUserWhenRemaining - Date.now();
    const logOutTime = expirationTime - Date.now();
    const moreThanWarningTime = warningTime > 0;
    const moreThanLogOutTime = logOutTime > 0;
    if (!moreThanLogOutTime) {
      triggerAutomaticSignOut();
      return;
    }
    if (moreThanWarningTime) {
      setState({ automaticSignOutWarning: false, minutes: 0, seconds: 0 });
      setTimer(TimerKeys.SignOutCountdown, getAccessTokenExpiration, warningTime);
    }
    if (!moreThanWarningTime) {
      if (extend) {
        setExtend(false);
        onExtendTicketClick();
      } else {
        setTimer(TimerKeys.AutomaticSignOut, getAccessTokenExpiration, logOutTime);
        window[TimerKeys.SignOutCountdown] = window.setInterval(() => setTimeLeft(expirationTime), 1000);
      }
    }
  };

  const getAccessTokenExpiration = (): void => {
    stopAllTimers();
    get<AccessTokenExpirationOperationResponse>(AccessTokenExpiration)
      .then(response => response && handleExpiration(response))
      .catch(error => handleError(error));
  };

  const onSignOutClick = (): void => {
    stopAllTimers();
    setAllowNavigation(true);
    window.location.replace(`${getTjenesterUrl()}/Auth/SignOut`);
  };

  const onExtendTicketClick = (): void => {
    stopAllTimers();
    put<AccessTokenExpirationOperationResponse>(AccessTokenExpiration)
      .then(response => response && handleExpiration(response))
      .catch(error => handleError(error));
  };

  const getTimeOutTime = (placeholder: string, text: string, value: number | undefined, appendZero = true): string => {
    let timeout = '';
    if (value === undefined) {
      return timeout;
    }

    if (value < 10 && appendZero) {
      timeout = replacePlaceholder(placeholder, text, '0' + value.toString());
    } else {
      timeout = replacePlaceholder(placeholder, text, value.toString());
    }

    return timeout;
  };

  const getTimeOutString = (): string => {
    let timeout = getTimeOutTime('{mm}', resources?.lightboxInactivityTimer ?? '', state.minutes);
    timeout = getTimeOutTime('{ss}', timeout, state.seconds);
    return timeout;
  };

  useEffect(() => {
    if (hasLoggedInCookie() || isAuthorized()) {
      handleExpiration();
    }
    subscribe(HeaderFooterEvents.setonshowsignoutbox, (event: CustomEvent) => {
      if (event.detail.onShowSignOutBox === SessionTimeoutAction.RequestExtension) {
        setExtend(true);
      } else {
        getAccessTokenExpiration();
      }
    });
  }, []);

  useEffect(() => {
    if (state.automaticSignOutWarning) {
      HNeventSoonAutoSignOut();
    }
  }, [state.automaticSignOutWarning]);

  if (!state.automaticSignOutWarning || !resources?.lightboxInactivityTitle) {
    return null;
  }
  return (
    <Modal
      title={resources?.lightboxInactivityTitle ?? ''}
      noCloseButton
      description={`${resources?.lightboxInactivityDescription}
      ${getTimeOutString()}
      ${resources?.lightboxInactivityDoYouWantToStay}`}
      primaryButtonText={resources?.lightboxInactivityButtonYes ?? ''}
      secondaryButtonText={resources?.lightboxInactivityButtonSignOut ?? ''}
      onSuccess={onExtendTicketClick}
      onClose={onSignOutClick}
    />
  );
};

export default WarningLightbox;
