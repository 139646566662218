import registerWebComp from '@helsenorge/framework-utils/web-component/register';

import PersonvelgerCms from './components/personvelger-cms';
import PersonvelgerComponent from './components/personvelger-component';
import PersonvelgerTjenester from './components/personvelger-tjenester';
import Session from './components/session';

async function enableMocking(): Promise<ServiceWorkerRegistration | void> {
  if (import.meta.env.MODE !== 'mocks') {
    return;
  }

  const { worker } = await import('./__mocks__/browser');

  return worker.start({
    onUnhandledRequest: 'bypass',
  });
}
enableMocking().then(() => {
  //Brukt som html-tag direkte framework via entrypage. Som betyr at den må være consumed i html-en fra serveren. Lagt til under basepage i pageConfiguration i HN-Tjenester
  registerWebComp(PersonvelgerTjenester, 'hn-webcomp-personvelger', { events: true }, 'hn-webcomp-personvelger-template');
  //WebCompConsumer i microweb: header-footer under menu-profile
  registerWebComp(PersonvelgerComponent, 'hn-webcomp-personvelger-component', { events: false }, 'hn-webcomp-personvelger-template');
  //Brukt som html-tag direkte via cms-et (HN-CMS). Consumed i html-en fra serveren ved id: hn-webcomp-personvelger-template
  //Tror path til hvor assets ligger på server må settes i spesufikt for cms-et ved HN-CMS/Source/Helsenorge/Features/Shared/Services/WebComponentAssetsService.cs
  registerWebComp(PersonvelgerCms, 'hn-webcomp-personvelger-cms', { events: false }, 'hn-webcomp-personvelger-template');
  //WebCompConsumer i microweb: header-footer under header-wrapper.
  registerWebComp(Session, 'hn-webcomp-session', { events: false }, 'hn-webcomp-personvelger-template');
});
