import { RepresentasjonsforholdType } from './entities/representasjon.v1enums';

/**
 * Session
 */
export interface AccessTokenExpirationOperationResponse {
  Expire: string;
}

export enum SessionTimeoutAction {
  Default = 0,
  RequestExtension = 1,
  RequestLogout = 2,
}

export interface Person {
  /** Brukers navn */
  fulltNavn: string;
  /** Brukers profilGuid */
  profilGuid: string;
  /** Om bruker er nåværende representasjon */
  selected: boolean;
  /** Type representasjonsforhold */
  representasjonforholdType: RepresentasjonsforholdType;
  /** Tekst som beskriver representasjonstypen */
  representasjonsType: string;
  /** Antall uleste varsler */
  antallUleste: number;
}
