import React, { useEffect } from 'react';

import { createHashRouter, RouterProvider, useLoaderData, RouteObject } from 'react-router-dom';

import Loader from '@helsenorge/designsystem-react/components/Loader';
import Spacer from '@helsenorge/designsystem-react/components/Spacer';
import Title from '@helsenorge/designsystem-react/components/Title';

import { Breakpoint, useBreakpoint } from '@helsenorge/designsystem-react';
import { setCookie } from '@helsenorge/framework-utils/cookie';
import { HNeventSetVisPersonvelger } from '@helsenorge/framework-utils/web-component/events';

import usePersonvelger from '../../hooks/usePersonvelger';
import { personvelgerLoader, PersonvelgerLoaderData } from '../../router/loader';
import ErrorElement from '../error';
import PersonvelgerListe from '../personliste';

export const Personvelger: React.FC = () => {
  const {
    users,
    errors: { profileError },
    loading,
    handleSelectUser,
    hasSelectedUser,
  } = usePersonvelger();
  const { headerFooter, personvelgerStatusMessages, representasjonsforholdError } = useLoaderData() as PersonvelgerLoaderData;
  const breakpoint = useBreakpoint();
  const smallSize = breakpoint === Breakpoint.xs;
  useEffect(() => {
    if (users?.length === 1) {
      setCookie('HN-ValgtRepresentasjon', '0');
      HNeventSetVisPersonvelger(false);
    }
    if (representasjonsforholdError) {
      HNeventSetVisPersonvelger(false);
    }
  }, [representasjonsforholdError]);

  const handleRedirect = (newPersonHasBeenSelected: boolean): void => {
    if (newPersonHasBeenSelected) {
      window.location.reload();
    } else {
      HNeventSetVisPersonvelger(false);
    }
  };

  const titleId = 'personvelger-title';

  return (
    <section aria-labelledby={titleId}>
      <Title htmlMarkup={'h1'} appearance={'title1'} margin={smallSize ? 1.5 : 2} id={titleId}>
        {headerFooter?.header.personvelger?.personvelgerHeader}
      </Title>

      {headerFooter?.header.personvelger && (
        <>
          <PersonvelgerListe
            messages={personvelgerStatusMessages}
            resources={headerFooter?.header.personvelger}
            users={users}
            onSelectUser={user => handleSelectUser(user, () => handleRedirect(!user.selected))}
            highlightUser={hasSelectedUser}
            errors={{
              profileError,
            }}
            id="personvelger-cms"
          >
            {loading && <Loader center />}
          </PersonvelgerListe>
          <Spacer size={smallSize ? 'xs' : 'l'} />
        </>
      )}

      <p>{headerFooter?.header.personvelger?.personvelgerIngress}</p>
    </section>
  );
};

export const routes: RouteObject[] = [
  {
    path: '*',
    loader: personvelgerLoader,
    element: <Personvelger />,
    errorElement: <ErrorElement />,
  },
];

const PersonvelgerCms: React.FC = () => {
  const router = createHashRouter(routes);

  return <RouterProvider router={router} />;
};

export default PersonvelgerCms;
