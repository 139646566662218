/// <reference types="@helsenorge/framework-utils/types/hn"/>

import { getTjenesterUrl } from '@helsenorge/framework-utils/hn-proxy-service';
import { debug } from '@helsenorge/framework-utils/logger';

export enum TimerKeys {
  SignOutCountdown = 'signOutCountdown',
  AutomaticSignOut = 'automaticSignOut',
  AutomaticSignOutWarning = 'automaticSignOutWarning',
  SoonAutoSignOutEvent = 'soonAutoSignOutEvent',
}

declare global {
  interface Window {
    [TimerKeys.SignOutCountdown]?: number;
    [TimerKeys.AutomaticSignOut]?: number;
    [TimerKeys.AutomaticSignOutWarning]?: number;
    [TimerKeys.SoonAutoSignOutEvent]?: number;
  }
}

export const createCrossDomainIFrame = (): void => {
  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  iframe.src = `${getTjenesterUrl()}/Core/CrossDomain/SessionKeepAlive`;
  iframe.id = 'cross_domain_iframe';
  document.body.appendChild(iframe);
};

interface SessionConfig {
  countdown: number;
  warnUserWhenRemaining: number;
  tokenLifetime: number;
}
export const getSessionConfig = (): SessionConfig => ({
  countdown: window.HN?.Rest?.TokenCountdown ?? 0,
  warnUserWhenRemaining: window.HN?.Rest?.TokenWarnUserWhenRemaining ?? 0,
  tokenLifetime: window.HN?.Rest?.TokenLifetime ?? 0,
});

export const getMinutesUntilSessionExpire = (expireDate: Date, warnUserWhenRemaining: number): number => {
  const now = new Date();
  now.setTime(now.getTime() + now.getTimezoneOffset() * 60000);
  return (expireDate.getTime() - now.getTime() - warnUserWhenRemaining) / 60000;
};

export const getCountdownTime = (countdown: number): { minutes: number; seconds: number } => {
  if (countdown < 0) {
    return { minutes: 0, seconds: 0 };
  }
  const minutes = Math.floor(countdown / 60000);
  const diff = countdown - minutes * 60000;
  const seconds = Math.floor(diff / 1000);
  return { minutes, seconds };
};

export const clearTimer = (timerId: TimerKeys): void => {
  if (!!window[timerId] && window[timerId] > -1) {
    clearTimeout(window[timerId]);
    window[timerId] = -1;
  }
};

export const setTimer = (timerId: TimerKeys, callback: () => void, timeout: number): void => {
  clearTimer(timerId);
  window[timerId] = window.setTimeout(callback, timeout);
};

export const clearIntervalTimer = (intervalId: TimerKeys): void => {
  if (!!window[intervalId] && window[intervalId] > -1) {
    window.clearInterval(window[intervalId]);
    window[intervalId] = -1;
  }
};

export const startIntervalTimer = (intervalId: TimerKeys, callback: () => void, delay: number): void => {
  if (window[intervalId] === -1) {
    window[intervalId] = window.setInterval(callback, delay);
  }
};

export const initializeRefreshTimeout = (getAccessTokenExpiration: () => void, timeTo: number): void => {
  const checkTimeout = (getAccessTokenExpiration: () => void, timeoutTime: number): void => {
    if (Date.now() < timeoutTime) {
      window.setTimeout(() => checkTimeout(getAccessTokenExpiration, timeoutTime), 1000);
    } else {
      getAccessTokenExpiration();
    }
  };

  checkTimeout(getAccessTokenExpiration, timeTo);
};

export const initSoonAutoSignOutEvent = (eventCallback: () => void, countdown: number, signOutCountdownId: TimerKeys): void => {
  const eventTimeBeforeSignout = 2 * 60 * 1000; // 2 minutes
  const timeout = countdown - eventTimeBeforeSignout;

  setTimer(
    TimerKeys.SoonAutoSignOutEvent,
    () => {
      if (!!window[signOutCountdownId] && window[signOutCountdownId] > -1) {
        debug('Trigger soonautosignoutevent ', new Date());
        eventCallback();
      }
    },
    timeout
  );
};

export const initializeAutomaticSignOut = (callback: () => void, countdown: number): void => {
  setTimer(TimerKeys.AutomaticSignOut, callback, countdown);
};

export const initializeAutomaticSignOutWarning = (expireDate: Date, callback: () => void, warnUserWhenRemaining: number): void => {
  const warningTime = expireDate.getTime() - Date.now() - warnUserWhenRemaining;
  setTimer(TimerKeys.AutomaticSignOutWarning, callback, warningTime);
};

export const initializeSignOutCountdown = (callback: () => void): void => {
  window[TimerKeys.SignOutCountdown] = window.setInterval(callback, 1000);
};

export const stopAllTimers = (): void => {
  const timerIds: TimerKeys[] = [
    TimerKeys.AutomaticSignOut,
    TimerKeys.AutomaticSignOutWarning,
    TimerKeys.SignOutCountdown,
    TimerKeys.SoonAutoSignOutEvent,
  ];

  timerIds.forEach(timerId => {
    clearTimer(timerId);
    clearIntervalTimer(timerId);
  });
};
