import { LoaderFunction, redirect } from 'react-router-dom';

import { Person } from '../types/entities';
import { HentAntallUlesteResponse, HentKunAntallUlesteHendelserResponse } from '../types/entities/hendelsesmeny.v2entities';
import { HentRepresentasjonsforholdV1Response } from '../types/entities/representasjon.v1entities';

import { fallbackHeaderFooter } from '@helsenorge/core-cms/header-skeleton/data';
import { HeaderFooterDTO } from '@helsenorge/core-cms/types/entities/cms.v1entities';
import LanguageLocales from '@helsenorge/core-utils/constants/languages';
import { get as cmsGet } from '@helsenorge/framework-utils/cms-content-api-service';
import { getUserLanguage } from '@helsenorge/framework-utils/hn-language';
import { getPath } from '@helsenorge/framework-utils/hn-page';
import { erTjenester, get as proxyGet } from '@helsenorge/framework-utils/hn-proxy-service';
import { getVisPersonvelger } from '@helsenorge/framework-utils/hn-user';

import { PersonvelgerStatusMessages } from '../components/messages';
import { getAntallUleste, getRepresentasjonsType } from '../utils/utils';

const PERSONVELGER_PATHNAME = '/velg-person';

const getPersonvelgerPath = (): string => {
  const path = getPath();

  if (path) {
    return '/' + path + PERSONVELGER_PATHNAME;
  }

  return PERSONVELGER_PATHNAME;
};

export const redirectLoader: LoaderFunction = async ({ request }): Promise<Response | null> => {
  const url = new URL(request.url);

  if (url.pathname === getPersonvelgerPath()) {
    return null;
  }

  if (erTjenester() && getVisPersonvelger()) {
    return redirect(PERSONVELGER_PATHNAME);
  }

  return null;
};

export interface PersonvelgerLoaderData {
  headerFooter: HeaderFooterDTO | null;
  personvelgerStatusMessages: PersonvelgerStatusMessages | null;
  representasjonsforholdError?: boolean;
  users: Person[] | undefined;
}

export const personvelgerLoader: LoaderFunction = async (): Promise<PersonvelgerLoaderData> => {
  const languageCode = getUserLanguage() || LanguageLocales.NORWEGIAN;

  const [representasjonsforholdResponse, kunAntallUlesteResponse, antallUlesteResponse, headerFooterResponse] = await Promise.allSettled([
    proxyGet<HentRepresentasjonsforholdV1Response>('representasjon', 'representasjonsforhold/v1'),
    proxyGet<HentKunAntallUlesteHendelserResponse>('Hendelsesmenyinternal', 'api/v2/Hendelse/KunAntallUleste'),
    proxyGet<HentAntallUlesteResponse>('Hendelsesmenyinternal', 'api/v2/AntallUleste'),
    cmsGet<HeaderFooterDTO>('headerfooter', { languageCode }),
  ]);

  const headerFooter = headerFooterResponse.status === 'fulfilled' ? headerFooterResponse.value : fallbackHeaderFooter;

  const representasjonsforhold = representasjonsforholdResponse.status === 'fulfilled' ? representasjonsforholdResponse.value : null;

  const representasjonsforholdError = representasjonsforholdResponse.status !== 'fulfilled';

  const currentUser =
    representasjonsforholdResponse.status === 'fulfilled'
      ? representasjonsforholdResponse.value?.representasjonsforhold.find(r => r.selected)
      : undefined;

  const kunAntallUleste = kunAntallUlesteResponse.status === 'fulfilled' ? kunAntallUlesteResponse.value : null;

  const antallUleste = antallUlesteResponse.status === 'fulfilled' ? antallUlesteResponse.value : null;

  const users: Person[] | undefined = representasjonsforhold?.representasjonsforhold?.map(user => ({
    fulltNavn: user.fulltNavn,
    profilGuid: user.profilGuid,
    selected: user.profilGuid === currentUser?.profilGuid,
    antallUleste: getAntallUleste(antallUleste, kunAntallUleste, user, currentUser),
    representasjonforholdType: user.representasjonsforholdType,
    representasjonsType: headerFooter?.header.personvelger
      ? getRepresentasjonsType(user.representasjonsforholdType, headerFooter?.header.personvelger)
      : '',
  }));

  return {
    headerFooter,
    personvelgerStatusMessages: representasjonsforhold,
    representasjonsforholdError,
    users,
  };
};
