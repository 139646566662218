import { useState } from 'react';

import { useNavigation, useLoaderData } from 'react-router-dom';

import { Person } from '../types/entities';
import { SettRepresentasjonsforholdV1Request, SettRepresentasjonsforholdV1Response } from '../types/entities/representasjon.v1entities';

import { post } from '@helsenorge/framework-utils/hn-proxy-service';

import { PersonvelgerLoaderData } from '../router/loader';

type UsePersonvelgerReturnType = {
  loading: boolean;
  users: Person[] | undefined;
  hasSelectedUser: boolean;
  handleSelectUser: (user: Person, onSuccess: () => void) => Promise<void>;
  errors: {
    profileError: boolean;
  };
};

const usePersonvelger = (): UsePersonvelgerReturnType => {
  const [loading, setLoading] = useState(false);
  const [profileError, setProfileError] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<Person>();
  const navigation = useNavigation();
  const { users: loadedUsers } = useLoaderData() as PersonvelgerLoaderData;

  const handleSelectUser = async (user: Person, onSuccess: () => void): Promise<void> => {
    try {
      setLoading(true);
      setSelectedUser(user);
      await post<SettRepresentasjonsforholdV1Response, SettRepresentasjonsforholdV1Request>('representasjon', 'representasjonsforhold/v1', {
        profilGuid: user?.profilGuid,
      });

      onSuccess();
    } catch {
      setProfileError(true);
    } finally {
      setLoading(false);
    }
  };

  const users = loadedUsers?.map(user => ({
    ...user,
    selected: selectedUser ? selectedUser?.profilGuid === user.profilGuid : user.selected,
  }));

  return {
    loading: navigation.state === 'loading' || loading,
    users,
    hasSelectedUser: !!selectedUser,
    handleSelectUser,
    errors: {
      profileError,
    },
  };
};

export default usePersonvelger;
