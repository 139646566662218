import React from 'react';

import NotificationPanel from '@helsenorge/designsystem-react/components/NotificationPanel';

import PortableText from '@helsenorge/core-cms/richtext';
import { isBlockModelList } from '@helsenorge/core-cms/richtext/portable-text-utils';
import { PersonvelgerDTO } from '@helsenorge/core-cms/types/entities/cms.v1entities';

import './styles.scss';

export interface PersonvelgerStatusMessages {
  harBarnMedAnnenAdresse?: boolean;
  harBarnUnder16UtenForeldreansvar?: boolean;
  harBarnOver11?: boolean;
  harBarnOver16?: boolean;
}

export interface PersonvelgerErrors {
  representasjonsforholdError?: boolean;
  profileError?: boolean;
}

export interface PersonvelgerProps {
  children: React.ReactNode;
  /** Tekster */
  resources: PersonvelgerDTO;
  /** Toggle visning av ulike statusmeldinger i personvelgeren */
  messages?: PersonvelgerStatusMessages | null;
  /** Feilmeldinger */
  errors?: PersonvelgerErrors;
}

const Messages: React.FC<PersonvelgerProps> = props => {
  return (
    <>
      <div className={'messages'}>
        {props.errors?.representasjonsforholdError && props.resources?.errorRepresentasjoner && (
          <NotificationPanel variant={'error'}>{props.resources?.errorRepresentasjoner}</NotificationPanel>
        )}

        {(props.messages?.harBarnOver11 || props.messages?.harBarnOver16) &&
          isBlockModelList(props.resources.representasjonerBarn16Plus) && (
            <NotificationPanel variant={'info'}>
              <PortableText value={props.resources.representasjonerBarn16Plus} />
            </NotificationPanel>
          )}

        {props.messages?.harBarnUnder16UtenForeldreansvar && isBlockModelList(props.resources.representasjonerManglendeForeldreansvar) ? (
          <NotificationPanel variant={'info'}>
            <PortableText value={props.resources.representasjonerManglendeForeldreansvar} />
          </NotificationPanel>
        ) : (
          props.messages?.harBarnMedAnnenAdresse &&
          isBlockModelList(props.resources.representasjonerBarnAnnenAdresse) && (
            <NotificationPanel variant={'info'}>
              <PortableText value={props.resources.representasjonerBarnAnnenAdresse} />
            </NotificationPanel>
          )
        )}

        {props.errors?.profileError && props.resources?.errorValgtPerson && (
          <NotificationPanel variant={'error'}>{props.resources?.errorValgtPerson}</NotificationPanel>
        )}

        {props.children}
      </div>
    </>
  );
};

export default Messages;
