import React from 'react';

import classNames from 'classnames';

import { Person } from '../../types/entities';
import { RepresentasjonsforholdType } from '../../types/entities/representasjon.v1enums';

import Avatar, { AvatarSize } from '@helsenorge/designsystem-react/components/Avatar';
import Badge from '@helsenorge/designsystem-react/components/Badge';
import Icon from '@helsenorge/designsystem-react/components/Icon';
import Check from '@helsenorge/designsystem-react/components/Icons/Check';
import ChevronRight from '@helsenorge/designsystem-react/components/Icons/ChevronRight';
import { palette } from '@helsenorge/designsystem-react/theme/palette';

import { Breakpoint, IconSize, useBreakpoint, useHover } from '@helsenorge/designsystem-react';

import { upperCaseFirstLetter } from '../../utils/utils';
import './styles.scss';

interface PersonListeItemProps {
  user: Person;
  highlight?: boolean;
  onSelectUser: () => void;
}

const PersonListeItem: React.FC<PersonListeItemProps> = props => {
  const highlight = props.highlight && props.user.selected;
  const isSelf = props.user.representasjonforholdType === RepresentasjonsforholdType.innloggetBruker;
  const breakpoint = useBreakpoint();
  const smallSize = breakpoint === Breakpoint.xs;
  const buttonClasses: string = classNames('personliste__person', {
    ['personliste__person--highlight']: highlight,
  });
  const avatarClasses: string = classNames({
    ['personliste__avatar--small']: smallSize,
    ['personliste__avatar--medium']: !smallSize,
    ['personliste__avatar--circle']: !isSelf,
  });
  const selectedClasses: string = classNames('personliste__selected', {
    ['personliste__selected--small']: smallSize,
    ['personliste__selected--medium']: !smallSize,
  });
  const { hoverRef, isHovered } = useHover<HTMLButtonElement>();
  const handleSelectUser: React.MouseEventHandler<HTMLButtonElement> = event => {
    event.preventDefault();

    props.onSelectUser();
  };

  return (
    <li className={'personliste__item'}>
      <button ref={hoverRef} type="button" className={buttonClasses} onClick={handleSelectUser}>
        {highlight ? (
          <span className={selectedClasses}>
            <Icon
              className={selectedClasses}
              svgIcon={Check}
              size={smallSize ? IconSize.XSmall : IconSize.Small}
              color={palette.blueberry600}
            />
          </span>
        ) : (
          <Avatar className={avatarClasses} size={smallSize ? AvatarSize.xsmall : AvatarSize.small}>
            {props.user.fulltNavn}
          </Avatar>
        )}
        <span className={'personliste__text'}>
          <strong className={'personliste__name'}>{props.user.fulltNavn}</strong>
          {props.user.representasjonsType && (
            <span className={'personliste__representasjonstype'}>{upperCaseFirstLetter(props.user.representasjonsType)}</span>
          )}
        </span>
        {!!props.user.antallUleste && <Badge color="cherry">{props.user.antallUleste}</Badge>}
        <span>
          <Icon className={'personliste__chevron'} svgIcon={ChevronRight} isHovered={isHovered} size={IconSize.XSmall} />
        </span>
      </button>
    </li>
  );
};

export default PersonListeItem;
