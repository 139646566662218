import React, { useEffect } from 'react';

import { erTjenester } from '@helsenorge/framework-utils/hn-proxy-service';
import { WebcompProps } from '@helsenorge/framework-utils/web-component/register';

import { createCrossDomainIFrame } from './session-utils';
import WarningLightbox from './warning-lightbox';

interface Props {
  isauthorized?: string;
  lightboxinactivitytitle?: string;
  lightboxinactivitydescription?: string;
  lightboxinactivitytimer?: string;
  lightboxinactivitydoyouwanttostay?: string;
  lightboxinactivitybuttonyes?: string;
  lightboxinactivitybuttonsignout?: string;
  inactivitysignedouttitle?: string;
  inactivitysignedoutbuttongotoroot?: string;
  inactivitysignedoutbuttonsigninagain?: string;
}

const Session: React.FC<Props & WebcompProps> = props => {
  const isAuthorized = props.isauthorized === 'true';
  useEffect(() => {
    if (!erTjenester() && isAuthorized) {
      createCrossDomainIFrame();
    }
  }, [isAuthorized]);
  return (
    <WarningLightbox
      resources={{
        lightboxInactivityTitle: props.lightboxinactivitytitle,
        lightboxInactivityDescription: props.lightboxinactivitydescription,
        lightboxInactivityTimer: props.lightboxinactivitytimer,
        lightboxInactivityDoYouWantToStay: props.lightboxinactivitydoyouwanttostay,
        lightboxInactivityButtonYes: props.lightboxinactivitybuttonyes,
        lightboxInactivityButtonSignOut: props.lightboxinactivitybuttonsignout,
        inactivitySignedOutTitle: props.inactivitysignedouttitle,
        inactivitySignedOutButtonGoToRoot: props.inactivitysignedoutbuttongotoroot,
        inactivitySignedOutButtonSignInAgain: props.inactivitysignedoutbuttonsigninagain,
      }}
    />
  );
};

export default Session;
