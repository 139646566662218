import React from 'react';

import { useNavigate, createBrowserRouter, RouterProvider, useLoaderData, Outlet, RouteObject } from 'react-router-dom';

import { RepresentasjonsforholdType } from '../../types/entities/representasjon.v1enums';

import Loader from '@helsenorge/designsystem-react/components/Loader';
import Spacer from '@helsenorge/designsystem-react/components/Spacer';
import Title from '@helsenorge/designsystem-react/components/Title';

import { Breakpoint, useBreakpoint } from '@helsenorge/designsystem-react';
import { trackRepresentation } from '@helsenorge/framework-utils/adobe-analytics';
import { getPath } from '@helsenorge/framework-utils/hn-page';
import { HNeventSetVisPersonvelger } from '@helsenorge/framework-utils/web-component/events';

import usePersonvelger from '../../hooks/usePersonvelger';
import { personvelgerLoader, PersonvelgerLoaderData, redirectLoader } from '../../router/loader';
import Container from '../container';
import ErrorElement from '../error';
import EventSubscriber from '../event-subscriber';
import PersonvelgerListe from '../personliste';
import RedirectProvider, { useRedirectContext } from '../redirect-provider';

export const Personvelger: React.FC = () => {
  const { headerFooter, personvelgerStatusMessages, representasjonsforholdError } = useLoaderData() as PersonvelgerLoaderData;
  const {
    users,
    errors: { profileError },
    loading,
    hasSelectedUser,
    handleSelectUser,
  } = usePersonvelger();
  const { redirectToUrlAfterLogin, redirectTo } = useRedirectContext();
  const breakpoint = useBreakpoint();
  const smallSize = breakpoint === Breakpoint.xs;
  const navigate = useNavigate();
  const handleRedirect = (newPersonHasBeenSelected: boolean, representasjonforholdType: RepresentasjonsforholdType): void => {
    if (redirectToUrlAfterLogin && redirectTo) {
      // Vertikalen har satt redirectToUrlAfterLogin til true og har satt redirectTo
      // til en URL de vil redirecte til
      window.location.replace(redirectTo);
    } else if (!newPersonHasBeenSelected && redirectTo) {
      // Vertikalen har satt redirectTo til en URL de vil redirecte til så lenge
      // bruker velger samme person som er valgt fra før
      window.location.replace(redirectTo);
    } else if (!newPersonHasBeenSelected) {
      if (representasjonforholdType === RepresentasjonsforholdType.innloggetBruker) {
        trackRepresentation('Eget bruk', 'Eget bruk');
      }

      // Bruker har valgt samme person som er valgt fra før, da kan vi skjule personvelger og vise vertikalen
      HNeventSetVisPersonvelger(false);
      navigate('/');
    } else {
      // Bruker har valgt å representere en ny person, da må vi gå til forsiden
      // fordi det kan være at den nye personen ikke har tilgang til tjenesten
      window.location.replace('/');
    }
  };

  const titleId = 'personvelger-title';

  return (
    <section aria-labelledby={titleId}>
      <Title htmlMarkup={'h1'} appearance={'title1'} margin={smallSize ? 1.5 : 2} id={titleId}>
        {headerFooter?.header.personvelger?.personvelgerHeader}
      </Title>

      {headerFooter?.header.personvelger && (
        <>
          <PersonvelgerListe
            messages={personvelgerStatusMessages}
            resources={headerFooter?.header.personvelger}
            users={users}
            onSelectUser={user => handleSelectUser(user, () => handleRedirect(!user.selected, user.representasjonforholdType))}
            highlightUser={hasSelectedUser}
            errors={{
              profileError,
              representasjonsforholdError,
            }}
            id="personvelger-tjenester"
          >
            {loading && <Loader center />}
          </PersonvelgerListe>
          <Spacer size={smallSize ? 'xs' : 'l'} />
        </>
      )}

      <p>{headerFooter?.header.personvelger?.personvelgerIngress}</p>
    </section>
  );
};

export const routes: RouteObject[] = [
  {
    path: '*',
    loader: redirectLoader,
    element: (
      <Container>
        <Outlet />
      </Container>
    ),
    errorElement: (
      <Container>
        <ErrorElement />
      </Container>
    ),
    children: [
      {
        path: 'velg-person',
        element: <Personvelger />,
        loader: personvelgerLoader,
      },
    ],
  },
];

const PersonvelgerPage: React.FC = () => {
  const router = createBrowserRouter(routes, { basename: '/' + getPath() });
  return (
    <RedirectProvider>
      <EventSubscriber>
        <RouterProvider router={router} />
      </EventSubscriber>
    </RedirectProvider>
  );
};

export default PersonvelgerPage;
