import React from 'react';

import './styles.scss';

interface ContainerProps {
  children: React.ReactNode;
}

const Container: React.FC<ContainerProps> = props => (
  <div className="container">
    <div className="row">
      <div className="col">
        <div className={'personvelger__container'}>{props.children}</div>
      </div>
    </div>
  </div>
);

export default Container;
