import React from 'react';

import { createHashRouter, createBrowserRouter, RouterProvider, useLoaderData } from 'react-router-dom';

import { Person } from '../../types/entities';

import Loader from '@helsenorge/designsystem-react/components/Loader';

import { erTjenester } from '@helsenorge/framework-utils/hn-proxy-service';
import { setAllowNavigation } from '@helsenorge/framework-utils/hn-user';
import { HNeventAttemptNavigation } from '@helsenorge/framework-utils/web-component/events';
import { WebcompProps } from '@helsenorge/framework-utils/web-component/register';

import usePersonvelger from '../../hooks/usePersonvelger';
import { personvelgerLoader, PersonvelgerLoaderData } from '../../router/loader';
import ErrorElement from '../error';
import PersonvelgerListe from '../personliste';
interface PersonvelgerProps {
  redirectUrl?: string;
}
export const Personvelger: React.FC<PersonvelgerProps> = props => {
  const {
    users,
    errors: { profileError },
    loading,
    handleSelectUser: handleSelectUserAction,
  } = usePersonvelger();
  const { headerFooter, personvelgerStatusMessages, representasjonsforholdError } = useLoaderData() as PersonvelgerLoaderData;

  const handleRedirect = (newPersonHasBeenSelected: boolean): void => {
    if (newPersonHasBeenSelected) {
      setAllowNavigation(true);
      window.location.replace(props.redirectUrl || '/');
    }
  };
  const handleSelectUser = (user: Person): void => {
    const allowNavigation = HNeventAttemptNavigation();

    if (allowNavigation) {
      handleSelectUserAction(user, () => handleRedirect(!user.selected));
    }
  };
  return (
    headerFooter?.header.personvelger && (
      <PersonvelgerListe
        messages={personvelgerStatusMessages}
        resources={headerFooter?.header.personvelger}
        users={users}
        onSelectUser={handleSelectUser}
        highlightUser={true}
        errors={{
          profileError,
          representasjonsforholdError,
        }}
        id="personvelger-component"
      >
        {loading && <Loader center />}
      </PersonvelgerListe>
    )
  );
};
interface PersonvelgerComponentProps {
  redirecturl?: string;
}
const PersonvelgerComponent: React.FC<PersonvelgerComponentProps & WebcompProps> = props => {
  const createRouter = erTjenester() ? createBrowserRouter : createHashRouter;

  const router = createRouter([
    {
      path: '*',
      loader: personvelgerLoader,
      element: <Personvelger redirectUrl={props.redirecturl} />,
      errorElement: <ErrorElement />,
    },
  ]);

  return <RouterProvider router={router} />;
};

export default PersonvelgerComponent;
